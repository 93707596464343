body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto Bk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body, html, ul, li {
  margin: 0;
  padding: 0;
}

nav {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0rem;
  color: black;
  position: fixed;
  text-align: center;
  width: 100%;
  background-color: #000;
  z-index: 1;
  padding-left: 10px;
}

li {
  display: inline-block;
  padding: 0 15px;
  font-family: 'Roboto Bk';
  text-transform: uppercase;
}

h5 {
  font-family: 'Roboto Bk';
  font-weight: normal;
  text-transform: uppercase;
  display: block;
  margin: 0;
  text-align: left;
}

h2 {
  font-family: 'Rubik Dirt';
  font-size: 2rem;
}

h3 {
  color: #d6fffa;
  font-size: 36px;
  font-family: 'Roboto Bk';
  text-transform: uppercase;
}

.background h3 {
  text-align: left;
  padding-top: 90px;
  padding-left: 30px;
}

main {
  padding-top: 58px;
}

section {
  /* height: 100vh;*/
  background: #000;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-color: black;
}

section.selected p {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

section.selected {
  border-left: 10vw solid black;
  border-right: 10vw solid black;
  -webkit-animation: BgAnimation 3s ease infinite;
  animation: BgAnimation 3s ease infinite;
  color: black;
  font-size: 100px;
}

@-webkit-keyframes BgAnimation {
  0% {
    background-position: 50% 50%;
}
  50% {
    background-position: 100% 100%;
}
  100% {
    background-position: 50% 50%;
}
}

@keyframes BgAnimation {
  0% {
    background-position: 50% 50%;
}
  50% {
    background-position: 100% 100%;
}
  100% {
    background-position: 50% 50%;
}
}


@media screen and (max-width: 535px) {
  main {
    padding-bottom: 150px;
  }
}



