.react-player-wrapper {
    display: block;
}


.player-wrapper {
    position: relative;
    padding-top: 80px;
}

.react-player {
   width: 100% !important;
   height: 100% !important;
}


.loader-container {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.arrow-container {
    display: none;
}

.arrow-container h5 {
    text-align: center;
    color: #d6fffa;
    font-size: 16px;
    padding-top: 20px;
}

.arrow-wrapper {
    cursor: pointer;
}



@media screen and (max-width: 1100px) {

.arrow-container {
        display: block;
    }


.react-player-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

.react-player {
        height: auto;
    }


}

@media screen and (max-height: 400px) {
.arrow-container {
        display: none;
    }
}
