.portfolio {
    padding-top: 90px;
}

.portfolioWrapper {
    height: 350px;
    overflow: auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
}

.track {
    width: 300px;
    margin-right: 20px;
}

.track_data {
    height: 200px;
}

.track h5 {
    margin-left: 20px;
    color: #d6fffa;
    font-family: 'Rubik Dirt';
    font-size: 32px;
}

.track h4 {
    color: #d6fffa;
    text-align: left;
    font-family: 'CATArena';
    font-size: 28px;
    margin: 0 0 0 20px;
}

.track p {
    color: #fff;
    text-align: left;
    font-family: 'CATArena';
    font-size: 16px;
    margin: 0 0 5px 20px;
}
