.navigation {
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 100%;
    background-color: white;
}

.brandName {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin: 0 2rem 0 1rem;
}

.logo {
    width: 300px;
    height: 60px;
}

.navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    width: calc(100% - 350px);
    position: absolute;
    top: 13px;
    left: 320px;
    margin-left: 10px;
}

.navigation-menu li {
    list-style-type: none;
}

.navigation-menu li a {
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    color: black;
    text-decoration: none;
    color: #d6fffa;
    -webkit-transition: color 0.3s, background-color 0.3s;
    -moz-transition: color 0.3s, background-color 0.3s;
    -o-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom: 5px solid transparent;
    font-family: 'CATArena';
    text-transform: uppercase;
}

.navigation-menu li a.selected {
    color: #2999ff;
    border-bottom: 5px solid #d6fffa;
}

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: #2642af;
}

.languages {
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 43px;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    margin-right: 10px;
}

.language {
    padding-left: 10px;
    cursor: pointer;
}

.language img {
    width: 25px;
    height: 25px;
    pointer-events: none;
}

@media screen and (max-width: 1100px) {
.navigation-menu {
        font-size: 0.75rem;
        top: 25px;
    }

.navigation-menu li {
        padding: 0;
    }

.navigation-menu li a {
        padding: 18px 10px;
    }

.brandName {
        margin: 0;
    }

.languages {
        top: 50px;
    }

    nav {
        height: auto;
    }
}

@media screen and (max-width: 900px) {
.hamburger {
        display: block;
        top: 37px;
    }

.navigation-menu {
        display: none;
    }

.navigation-menu-expanded {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 300px;
        height: auto;
        background-color: #000;
        border-top: 1px solid black;
        overflow: auto;
    }

.navigation-bar li {
        text-align: center;
        padding: 20px;
    }

.navigation-bar li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
        text-decoration: none;
        color: #d6fffa;
        -webkit-transition: color 0.3s, background-color 0.3s;
        -moz-transition: color 0.3s, background-color 0.3s;
        -o-transition: color 0.3s, background-color 0.3s;
        transition: color 0.3s, background-color 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        border-bottom: 5px solid transparent;
        font-family: 'Roboto Bk';
    }

.navigation-bar li:hover {
        color: #2999ff;
        background-color: #95b2af;
    }

.navigation-bar li a.selected {
        color: #2999ff;
    }

.languages {
        right: 75px;
        top: 40px;
    }
}

@media screen and (max-width: 535px) {
.logo {
        width: 150px;
        height: 30px;
    }

.language img {
        width: 20px;
        height: 20px;
    }

.languages {
        top: 25px;
    }

.hamburger {
        top: 25px;
    }

.navigation-menu-expanded {
        top: 47px;
    }
}

@media screen and (max-height: 400px) {
    .navigation-menu-expanded {
       display: table;
    }
}
