.backgroundAbout {
    background-image: url("../img/diezel.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-bottom: 90px;
}

.descWrapper {
    margin-left: 30px;
    width: 60%;
    text-align: left;
}

.descWrapper p {
    font-family: 'Roboto Bk';
    font-size: 24px;
    line-height: 32px;
    text-shadow: 3px 3px 3px #000;
}

section.selected p {
    color: #fff;
    text-shadow: 3px 3px 3px #000;
}

@media screen and (max-width: 900px) {
.descWrapper p {
        font-size: 18px;
        line-height: 28px;
        text-shadow: 3px 3px 3px #000;
    }
}

@media screen and (max-width: 535px) {
.descWrapper p {
        font-size: 14px;
        line-height: 20px;
        text-shadow: 3px 3px 3px #000;
    }
}
