.footer {
    background-color: #000;
    height: 55px;
    width: 100%;
    color: #fff;
    position: fixed;
    z-index: 1;
    bottom: 0;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-family: 'Roboto Bk';
}

.data-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.social-icon {
    width: 25px !important;
    height: 25px !important;
    margin-right: 10px;
}

.icon-block {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.footer h5 {
    font-size: 10px;
}

.footer h5 a {
    display: inline;
    width: 100%;
    color: black;
    text-decoration: none;
    color: #d6fffa;
    -webkit-transition: color 0.3s, background-color 0.3s;
    -moz-transition: color 0.3s, background-color 0.3s;
    -o-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom: 5px solid transparent;
    font-family: 'Roboto Bk';
    text-transform: uppercase;
}

@media screen and (max-width: 535px) {
.footer {
        flex-direction: column;
        height: 140px;
        justify-content: space-evenly;
    }

.footer h5 {
        text-align: center;
    }

.data-wrapper {
        margin-bottom: 10px;
    }

.icon-block {
        justify-content: center;
    }
}
