.equipment {
    padding-top: 90px;
}

.equipmentWrapper {
}

.equipmentLists {
    display: inline;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
}

.equipmentCard {
    display: inline-block;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    width: 250px;
    margin-bottom: 20px;
}

.equipmentCard h5 {
    color: #2999ff;
    font-family: 'Rubik Dirt';
    font-size: 24px;
    padding-left: 16px;
    margin: 0;
}

.equipmentCard ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 200px;
}

.equipmentCard ul li {
    color: #d6fffa;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
}
