.photogallery {
    padding-top: 90px;
}

.photogalleryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow: auto;
}

.photogalleryWrapper {

  width: 1100px;
}

/* Для полосы прокрутки */
::-webkit-scrollbar {
    width: 10px;
    background-color: #d6fffa;
}

::-moz-scrollbar {
    width: 10px;
    background-color: #d6fffa;
}

/* Для трека (фоновая область) */
::-webkit-scrollbar-track {
    background-color: #d6fffa;
}

::-moz-scrollbar-track {
    background-color: #d6fffa;
}

/* Для ползунка (активная область) */
::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 5px;
    border: 1px solid #fff;
}

::-moz-scrollbar-thumb {
    background-color: #000;
    border-radius: 5px;
    border: 1px solid #fff;
}

::-webkit-scrollbar-button {
    display: none;
}

/* Стили для кнопок навигации */
.react-images__arrow {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
}

.react-images__arrow:focus {
    outline: none;
}

.react-images__arrow:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

/* Стили для точек навигации */
.react-images__dot {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.react-images__dot.react-images__dot--selected {
    background-color: #000;
}

/* Стили для контейнера навигации */
.react-images__navigation {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: transparent;
    border: none;
}

/* Стили для контейнера слайдера */
.react-images__view {
    position: relative;
}

@media screen and (max-width: 1100px) {
.photogalleryContainer {
        display: block;
    }
}

@media screen and (max-height: 400px) {

.react-photo-gallery--gallery {
        display: inline;
    }
}
