.backgroundServices {
    background-image: url("../img/ampeg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 90px;
}

.servicesWrapper {
    margin-left: 30px;
    width: 80%;
    text-align: left;
}

.servicesWrapper p {
    font-family: 'Roboto Bk';
    font-size: 24px;
    line-height: 32px;
    text-shadow: 3px 3px 3px #000;
    margin-bottom: 30px;
}

.servicesWrapper ul {
    font-family: 'Roboto Bk';
    font-size: 18px;
    line-height: 24px;
    list-style-type: circle;
}

.servicesWrapper ul li {
    margin-bottom: 20px;
    width: 80%;
}

.servicesWrapper ul li em {
    color: #2999ff;
}

.servicesWrapper p a {
    color: #2999ff;
    -webkit-transition: color 0.3s, background-color 0.3s;
    -moz-transition: color 0.3s, background-color 0.3s;
    -o-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-family: 'CATArena';
    font-size: 26px;
}

section.selected p {
    color: #fff;
    text-shadow: 3px 3px 3px #000;
    margin-bottom: 30px;
}

section.selected ul {
    color: #fff;
    text-shadow: 3px 3px 3px #000;
    list-style-type: circle;
}

section.selected ul li {
    color: #fff;
    text-shadow: 3px 3px 3px #000;
}

@media screen and (max-width: 900px) {
.servicesWrapper p {
        font-size: 18px;
        line-height: 28px;
        text-shadow: 3px 3px 3px #000;
    }

.servicesWrapper p a {
        font-size: 18px;
        line-height: 28px;
    }

.servicesWrapper ul {
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (max-width: 535px) {
.servicesWrapper p {
        font-size: 14px;
        line-height: 20px;
        text-shadow: 3px 3px 3px #000;
    }

.servicesWrapper p a {
        font-size: 14px;
        line-height: 20px;
    }

.servicesWrapper ul {
        font-size: 12px;
        line-height: 16px;
    }
}
