.rules {
    background-image: url("../img/console.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 100px 25px 25px;
}

.rules .languages {
    position: fixed;
    top: 40px;
    right: 25px;
}

.rules p {
    text-align: left;
    margin-bottom: 30px;
    fomt-size:14px;
    -webkit-text-stroke-width: 0.5px; /* Ширина обводки */
    -webkit-text-stroke-color: #000; /* Цвет обводки */
}

.backBtn {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    position: fixed;
    top: 40px;
    left: 25px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.backBtn:hover {
    background-color: #2642af;
}
